<template>
  <div
    v-if="status == 'authenticated'"
    class="flex flex-wrap items-center mb-7"
  >
    <div
      class="w-32 rounded-full shadow bg-bg_alt_color text-nav_color border-2 border-gray-400"
    >
      <NuxtImg
        v-if="image"
        provider="cacheIpx"
        preset="modified"
        class="w-full rounded-full aspect-square"
        :src="image"
        :alt="userName"
        width="128"
        height="128"
      />
      <div v-else class="flex items-center justify-center w-full h-32">
        <span class="text-5xl font-bold uppercase leading-none">
          {{ avatarChar }}
        </span>
      </div>
    </div>
    <div class="w-12"></div>
    <div class="text-center">
      <div class="text-lg font-semibold ">{{ userName }}</div>
      <div v-if="data?.email">{{ data?.email }}</div>
      <div v-if="data?.phone" dir="ltr">{{ data?.phone }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { status, data } = useAuth()
const { t } = useI18n()

const image = computed(() => {
  return useHttpUrl(data.value?.picture)
})

const avatarChar = computed(() => {
  return (
    `${data.value?.first_name?.charAt(0) || ''} ${
      data.value?.last_name?.charAt(0) || ''
    }`.trim() || 'v'
  )
})

const userName = computed(() => {
  return (
    `${data.value?.first_name || ''} ${data.value?.last_name || ''}`.trim() ||
    `${t('first_name')} ${t('last_name')}`
  )
})
</script>
